import Cookies from "js-cookie";

export const callApi = async (url, options) => {
  // dispatch(setLoading(true));

  const token = Cookies.get("viqr_token");
  let headers;
  const API = `https://api.viqr.in/${url}`;
  // options?.data ? headers = { "Content-Type": "application/json" } : "";
  if (options?.data) {
    headers = { "Content-Type": "application/json" };
  } else {
    headers = "";
  }
  // console.log(token)
  try {
    const raw = await fetch(API, {
      headers: {
        ...(token &&
          token !== undefined && { Authorization: `Bearer ${token}` }),
        ...headers,
        // "Content-Type": "application/json",
      },
      method: options?.method || "GET",
      body: options?.data
        ? JSON.stringify(options?.data)
        : options?.form_data
        ? options?.form_data
        : undefined,
    });

    const data = await raw.json();
    console.log("api helper data", data);
    // if (!raw.ok) throw new Error(data);
    return data;
  } catch (error) {
    return error;
  } finally {
    // dispatch(setLoading(false))
  }
};

export const POST_DATA_HEADEERS = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + Cookies.get("viqr_token"),
};

// import { callApi, POST_DATA_HEADEERS } from './path/to/your/utilityFile';

// // Example of making a GET request
// const fetchData = async () => {
//   try {
//     const data = await callApi('endpoint', { method: 'GET' });
//     console.log('Data:', data);
//   } catch (error) {
//     console.error('Error fetching data:', error);
//   }
// };

// // Example of making a POST request
// const postData = async () => {
//   const postData = { key: 'value' }; // Your payload data
//   try {
//     const data = await callApi('endpoint', { method: 'POST', data: postData });
//     console.log('Response:', data);
//   } catch (error) {
//     console.error('Error posting data:', error);
//   }
// };

// Example of using POST_DATA_HEADEERS for a POST request
// const postDataWithHeaders = async () => {
//   const postData = { key: 'value' }; // Your payload data
//   try {
//     const response = await fetch('your-api-endpoint', {
//       method: 'POST',
//       headers: POST_DATA_HEADEERS,
//       body: JSON.stringify(postData),
//     });
//     const data = await response.json();
//     console.log('Response:', data);
//   } catch (error) {
//     console.error('Error posting data:', error);
//   }
// };
