import { useEffect, useState } from "react";
import CommonTable from "../../Components/Common/CommonTable";
import MainHeading from "../../Components/Common/MainHeading";
import MainLayout from "../../Components/Layouts/MainLayout";
import CreateQrModal from "./CreateQrModal";
import { callApi } from "../../utility/useapi";
import { ApiUrl } from "../../utility/ApiUrls";

export default function GenerateQR() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [QrList, setQrList] = useState([]);
  const [pageStates, setPageStates] = useState({
    currentPage: 1,
    pageSize: 10,
    count: 0,
    totalPages: 0,
    tableLoader: false,
    SiteData: [],
    filterData: "",
  });

  const toggleModal = () => setModalOpen(!modalOpen);
  const TableHeading = [
    {
      label: "#",
      value: "index",
    },
    {
      label: "Batch No.",
      value: "id",
    },
    {
      label: "Category",
      value: "category",
    },

    // {
    //   label: "Status",
    //   value: "status",
    // },
  ];

  const ActionData = [
    {
      icon: <i class="fas fa-download"></i>,
      label: "Download",
      clickHandler: (data) => {
        DownloadQRCodes(data);
      },
    },
    // {
    //   icon: <i class="fas fa-eye"></i>,
    //   label: "View",
    //   action: () => {
    //     // handle action
    //   },
    // },

    // {
    //   icon: <i class="fas fa-trash-alt"></i>,
    //   label: "Deactivate",
    //   action: () => {
    //     // handle action
    //   },
    // },
  ];
  const HeadingButtons = [
    {
      label: "Generate QR",
      function: () => {
        toggleModal();
      },
    },
  ];

  useEffect(() => {
    QRList();
  }, []);

  const QRList = () => {
    // ?filters=name%3Atest&searchQuery=test&pageNo=1&pageSize=10
    callApi(`${ApiUrl?.getBetchList}`).then((response) => {
      // console.log("Error...", response)
      setIsLoading(false);
      if (response?.status === true) {
        setQrList(response?.data?.batches);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: "10",
        }));
      } else {
      }
    });
  };

  const PageChangeHandler = (newPage) => {
    setPageStates((prevState) => ({
      ...prevState,
      currentPage: newPage,
      tableLoader: true,
    }));

    callApi(
      `${ApiUrl?.getBetchList}?pageNo=${newPage}&pageSize=${pageStates?.pageSize}`
    ).then((response) => {
      setPageStates((prevState) => ({
        ...prevState,
        tableLoader: false,
      }));
      if (response?.status === true) {
        setQrList(response?.data?.batches);
        setPageStates((prevState) => ({
          ...prevState,
          count: response?.data?.totalData,
          totalPages: response?.data?.totalPages,
        }));
      } else {
        // Handle errors
      }
    });
  };

  const DownloadQRCodes = (data) => {
    console.log("Downloading QR Codes for:", data);

    callApi(`${ApiUrl?.getBetchExcel}${data?.id}`)
      .then((response) => {
        setIsLoading(false);

        if (response?.status === true) {
          console.log("Response Data:", response?.data);

          // Assuming response.data contains the Base64 content of the file
          const base64String = response?.data?.fileContents; // Update based on your API response key

          // Decode Base64 to binary data
          const binaryData = atob(base64String);
          const binaryArray = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            binaryArray[i] = binaryData.charCodeAt(i);
          }

          // Create a Blob from the binary data
          const blob = new Blob([binaryArray], {
            type: "application/vnd.ms-excel",
          }); // Adjust MIME type if needed

          // Generate a temporary download URL
          const url = URL.createObjectURL(blob);

          // Create a temporary anchor element to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = "Batch_QR_Codes.xlsx"; // Set desired file name
          document.body.appendChild(link);
          link.click();

          // Cleanup: Remove link and revoke object URL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        } else {
          console.error(
            "Failed to fetch file data:",
            response?.message || "Unknown error"
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("API Error:", error);
      });
  };

  return (
    <>
      <MainLayout>
        <MainHeading title={"QR Management"} HeadingButtons={HeadingButtons} />
        <CommonTable
          TableHeading={TableHeading}
          TableData={QrList}
          ActionData={ActionData}
          currentPage={pageStates?.currentPage}
          totalPages={pageStates?.totalPages}
          PageChangeHandler={PageChangeHandler}
        />
      </MainLayout>
      <CreateQrModal
        isOpen={modalOpen}
        toggle={toggleModal}
        TableList={QrList}
      />
    </>
  );
}
