import moment from "moment";
import DetailTitle from "./DetailTitle";

export default function ({ listData, detailHeading }) {

  const renderListData = (item, listData,) => {
    console.log("itemtataaa///././", item);
    switch (item?.value) {
      case "profilePhoto":
        return (
          <li class="col-12">
            <p>
              <img class="detail-img" src={`data:image/jpeg;base64,${listData[item?.value]}`} alt="" />
            </p></li>)


      case "date":
        return <li class="col-12 col-md-6 col-lg-4"><p>
          <span class="text-muted fs-5 d-block">{item?.label} :-</span>
          {moment(listData[item?.value]).format("DD-MM-YYYY") || "N/A"}
        </p>
        </li>
      default:
        return <li class="col-12 col-md-6 col-lg-4"><p>
          <span class="text-muted fs-5 d-block">{item?.label} :-</span>
          {listData[item?.value] || "N/A"}
        </p>
        </li>
    }
  }

  return (
    detailHeading?.map((heading) => <>
      {heading?.title && <DetailTitle
        title={heading?.title}
      />}
      <ul class="row list-unstyled">
        {heading?.data?.map((item, index) => renderListData(item, listData)
        )}
      </ul>
    </>))
}