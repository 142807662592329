export default function DetailTitle({ title, status }) {
  return (
    <div class="d-flex align-items-center justify-content-between border-bottom mb-3">
      <h4 class="d-flex align-items-center mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
          <circle cx="10" cy="10" r="2" fill="#000000"></circle>
        </svg>
        {title}
      </h4>
      <div>
        {status && <p>
          <span class="text-muted fs-5">Status :-</span>
          <span class="badge bg-green-25 fw-normal rounded-pill">{status}</span>
        </p>}
      </div>
    </div>
  )
}