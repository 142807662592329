import PaginationComponent from "./Pagination";

export default function CommonTable({
  TableHeading,
  TableData,
  isLoading,
  currentPage,
  count,
  totalPages,
  PageChangeHandler,
  ActionData,
  handleStatusToggle,
}) {
  const renderTableData = (heading, data, index) => {
    // console.log("TableRenderDataaaa", heading, data);
    // console.log("count>>>>>", count, "totalPages>>>>>>>", currentPage);
    // console.log("TableData>>>>>in>>>CommonTable>>>>>>", TableData);

    console.log("data", [heading.key]);

    switch (heading.value) {
      case "index":
        return (
          <td>
            {((currentPage - 1) * 10 + index + 1).toString().padStart(2, "0")}
          </td>
        );
      case "toggleStatus":
        return (
          <td>
            <div className="form-check form-switch d-flex justify-content-center">
              <input
                type="checkbox"
                className="form-check-input rounded-4"
                checked={data[heading.value]}
                onChange={() =>
                  handleStatusToggle(data?.id, data[heading.value])
                }
              />
            </div>
          </td>
        );
      case "isDocVerified":
        let verifyStatus;
        if (data[heading.key] === true) {
          verifyStatus = (
            <td>
              <span className="badge bg-green fw-normal rounded-pill">Yes</span>
            </td>
          );
        } else {
          verifyStatus = (
            <td>
              <span className="badge bg-danger fw-normal rounded-pill">No</span>
            </td>
          );
        }
        return verifyStatus;
      case "Status":
        let status;
        if (data[heading.key] === true) {
          status = (
            <td>
              <span className="badge bg-green fw-normal rounded-pill">Yes</span>
            </td>
          );
        } else {
          status = (
            <td>
              <span className="badge bg-danger fw-normal rounded-pill">No</span>
            </td>
          );
        }
        return status;
      case "index":
        return (
          <td>
            {((currentPage - 1) * 10 + index + 1).toString().padStart(2, "0")}
          </td>
        );

      case "category":
        let category;
        if (data[heading.key] === 0) {
          category = (
            <td>
              <span className="badge bg-green fw-normal rounded-pill">car</span>
            </td>
          );
        } else {
          category = (
            <td>
              {console.log("category", data[heading.key])}
              <span className="badge bg-danger fw-normal rounded-pill">No</span>
            </td>
          );
        }
        return category;

      default:
        return <td className="text-wrap">{data[heading.value] || "--"}</td>;
    }
  };
  return (
    <div className="manage-table main-table">
      <table className="table table-border-bottom align-middle nowrap  w-100">
        <thead>
          <tr>
            {TableHeading?.map((data, index) => (
              <th
                key={index}
                className="text-muted text-small text-uppercase  text-nowrap"
              >
                {data?.label}
              </th>
            ))}
            {ActionData && (
              <th className="text-muted text-small text-uppercase  text-nowrap">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody className={isLoading ? "loading-skeleton" : ""}>
          {isLoading ? (
            <>
              {[1, 2, 3].map((item, index) => (
                <tr key={index}>
                  {TableHeading &&
                    TableHeading?.length > 0 &&
                    [...TableHeading]?.map((action, index) => (
                      <td key={index}>
                        <div className="">demo</div>
                      </td>
                    ))}
                  {ActionData?.length > 0 && (
                    <td>
                      <div className="">demo</div>
                    </td>
                  )}
                </tr>
              ))}
            </>
          ) : TableData && TableData?.length > 0 ? (
            TableData?.map((data, index) => {
              return (
                <tr>
                  {TableHeading?.map((heading) =>
                    renderTableData(heading, data, index)
                  )}
                  <td>
                    <div className="text-center">
                      {ActionData && (
                        <div className="dropdown d-inline-block">
                          <button
                            class="btn btn-white dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fas fa-ellipsis-v fs-14 text-primary"></i>
                          </button>
                          <ul className="dropdown-menu shadow-lg">
                            {ActionData?.length > 0 &&
                              ActionData?.map((action, index) => {
                                return (
                                  <li key={index}>
                                    <a
                                      className="btn btn-white dropdown-toggle"
                                      role="button"
                                      onClick={() => action?.clickHandler(data)} // Pass `data` here
                                    >
                                      {action?.icon}
                                      {action?.label}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="">
              <td colSpan={18} className="text-center fw-medium">
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* <nav className="paggination-nav" aria-label="Page navigation example">
        <ul className="paggination">
          <li className="page-item">
            <a className="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M11.8002 13.6004L8.2002 10.0004L11.8002 6.40039"
                    stroke="#666D80"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </a>
          </li>
          <li className="page-item">
            <a className="page-link active" href="#">
              1
            </a>
          </li>

          <li className="page-item">
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
                    stroke="#666D80"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </a>
          </li>
        </ul>
      </nav> */}

      {totalPages > 1 && (
        <div className="d-flex justify-content-between align-items-center paggination-nav">
          {count > 0 && !isLoading && (
            <p className="mb-0 text-muted fs-6 text-uppercase">
              {`Showing ${currentPage * 10 - 9} to ${currentPage * 10 < count ? currentPage * 10 : count
                } of ${count} entries`}
            </p>
          )}

          <PaginationComponent
            currentPage={currentPage}
            PageChangeHandler={PageChangeHandler}
            totalPages={totalPages}
          />
        </div>
      )}
    </div>
  );
}
