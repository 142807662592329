import CommonTable from "../../Components/Common/CommonTable";
import MainHeading from "../../Components/Common/MainHeading";
import MainLayout from "../../Components/Layouts/MainLayout";

export default function Banner() {
  const TableHeading = [
    {
      label: "#",
      title: "#"
    },
    {
      label: "User Name",
      title: "userName"
    },
    {
      label: "Email",
      title: "email"
    },
    {
      label: "Mobile No.",
      title: "mobileNo"
    },
    {
      label: "Creation Date",
      title: "creationDate"
    },
    {
      label: "Documents Verification",
      title: "documentVer"
    },
    {
      label: "Status",
      title: "status"
    }
  ]

  const ActionData = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",
      action: () => {
        // handle action
      }
    },
    // {
    //   icon: <i class="fas fa-file"></i>,
    //   label: "Documents",
    //   action: () => {
    //     // handle action
    //   }
    // },
    // {
    //   icon: <i class="fas fa-trash-alt"></i>,
    //   label: "Deactivate",
    //   action: () => {
    //     // handle action
    //   }
    // },
  ]
  const HeadingButtons = [
    {
      label: "Create Banner",
      function: () => { }
    }
  ]
  return (<>
    <MainLayout>
      <MainHeading title={"Banner Management"} HeadingButtons={HeadingButtons} />
      <CommonTable TableHeading={TableHeading} ActionData={ActionData} />
    </MainLayout>
  </>)
}