import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../Components/Layouts/MainLayout";
import MainHeading from "../../Components/Common/MainHeading";
import DetailCard from "./Components/DetailCard";
import DetailTitle from "./Components/DetailTitle";
import DetailList from "./Components/DetailList";
import { useEffect, useState } from "react";
import { ApiUrl } from "../../utility/ApiUrls";
import { callApi } from "../../utility/useapi";
import moment from "moment";

export default function UserDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    userList();
    console.log("ddd", location?.state);
  }, []);

  const userList = () => {
    // ?filters=name%3Atest&searchQuery=test&pageNo=1&pageSize=10
    callApi(`${ApiUrl?.userDetail}${location?.state?.userGUID}`).then(
      (response) => {
        // console.log("Error...", response)
        // setIsLoading(false);
        if (response?.status === true) {
          // setUsersList(response?.data?.user);
          // setPageStates((prevState) => ({
          //   ...prevState,
          //   count: response?.data?.totalData,
          //   totalPages: response?.data?.totalPages,
          // }));
        } else {
        }
      }
    );
  };
  const [UserDetailsData, setUserDetailsData] = useState({
    image: "",
    userName: "jeet@123",
    email: "",
    dob: "",
    contact: "",
    altContact: "",
    address: "",
    state: "",
    city: "",
    zip: "",
    country: "",
    verified: false,
    reason: "",
    addedBy: "",
    addedOn: "",
    updatedBy: "",
    updatedOn: "",
  });
  const backButtonHandler = () => {
    navigate(-1);
  };
  const UserDetailsHeading = [
    {
      title: "",
      data: [
        {
          label: "image",
          value: "profilePhoto",
        },
        {
          label: "User Name",
          value: "name",
        },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "DOB",
          value: "date",
        },
        {
          label: "contact",
          value: "phone",
        },
        {
          label: "contact (Alternate)",
          value: "alternatePhone",
        },
        {
          label: "Address",
          value: "address",
        },
        {
          label: "State",
          value: "state",
        },
        {
          label: "City",
          value: "city",
        },
      ],
    },
    {
      title: "Health Details",
      data: [
        {
          label: "Blood Group",
          value: "bloodGroup",
        },
        {
          label: "Disease",
          value: "disease",
        },
      ],
    },
    {
      title: "ID Details",
      data: [
        {
          label: "Aadhar No.",
          value: "aadhaarNumber",
        },
      ],
    },
    {
      title: "License Details",
      data: [
        {
          label: "Vehicle type",
          value: "vehicleType ",
        },
        {
          label: "Vehicle Registration No.",
          value: "vehicleRegNo",
        },
        {
          label: "Insurance Company",
          value: "insuranceCompany",
        },
        {
          label: "Policy Number",
          value: "policyNumber",
        },
        {
          label: "Expiry Date",
          value: "expiryDate",
        },
      ],
    },
    {
      title: "SoS Details",
      data: [
        {
          label: "Member Name",
          value: "memberName",
        },
        {
          label: "Mobile No.",
          value: "mobileNo",
        },
      ],
    },
  ];

  useEffect(() => {
    console.log("locooo...", location?.state);
    UserDataList();
  }, []);

  const UserDataList = () => {
    callApi(`${ApiUrl?.userDetail}${location?.state?.userGUID}`).then(
      (response) => {
        setUserDetailsData((prev) => ({
          ...response?.data,
          // dob: moment(response?.data?.dob).format('DD-MM-YYYY'),
        }));
        // console.log("Error...", response)
        // setIsLoading(false);
        // if (response?.status === true) {
        //   setUsersList(response?.data?.user);
        //   setPageStates((prevState) => ({
        //     ...prevState,
        //     count: response?.data?.totalData,
        //     totalPages: "10",
        //   }));
        // } else {
        // }
      }
    );
  };

  return (
    <>
      <MainLayout>
        <MainHeading
          title={"User Detail"}
          backButtonHandler={backButtonHandler}
        />
        <DetailCard>
          <DetailTitle title={"Basic Details"} status={"Active"} />
          <DetailList
            detailHeading={UserDetailsHeading}
            listData={UserDetailsData}
          />
        </DetailCard>
      </MainLayout>
    </>
  );
}
