import Cards from "../../Components/Common/Cards"
import CommonTable from "../../Components/Common/CommonTable"
import MainHeading from "../../Components/Common/MainHeading"
import MainLayout from "../../Components/Layouts/MainLayout"

export default function Complaints() {
  const UserCard = [
    {
      cardSvg: <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="18"
        height="18"
        x="0"
        y="0"
        viewBox="0 0 1024 1024"
        style={{ enableBackground: "new 0 0 512 512" }}
        xmlSpace="preserve"
        class="hovered-paths"
      >
        <g>
          <path
            fill="#731CE5"
            d="M719.3 890.8c-15.1 0-29.6-6.1-40.1-16.9l-91.9-93.8c-11.9-12.1-11.7-31.6.5-43.5 12.1-11.9 31.6-11.7 43.5.5l87.9 89.8L904.9 612c11.1-12.8 30.5-14.3 43.3-3.2s14.3 30.5 3.2 43.3L761.9 871.3c-10.2 11.9-25.1 18.9-40.8 19.4-.7.1-1.2.1-1.8.1z"
            opacity="1"
            data-original="#ff623e"
          ></path>
          <path
            fill="#731CE5"
            d="M703.2 484.2c-16.5-6.8-33.4-12.4-50.6-16.7 53-42 87.1-106.9 87.1-179.7 0-126.4-102.9-229.3-229.3-229.3S281.1 161.4 281.1 287.8c0 72.8 34.1 137.7 87.1 179.7C203.1 509.7 80.6 659.7 80.6 837.8v21.5c0 55.1 44.9 99.9 99.9 99.9h449.9c16.9 0 30.7-13.8 30.7-30.7s-13.8-30.7-30.7-30.7H180.6c-21.2 0-38.5-17.3-38.5-38.5v-21.5C142.1 661 286 517 462.9 517H558c42.2 0 83.2 8 121.9 24 15.7 6.5 33.6-1 40.1-16.7 6.3-15.6-1.2-33.6-16.8-40.1zM342.6 287.8c0-92.5 75.3-167.8 167.8-167.8s167.8 75.3 167.8 167.8-75.3 167.8-167.9 167.8-167.7-75.2-167.7-167.8z"
            opacity="1"
            data-original="#303135"
            class="hovered-path"
          ></path>
        </g>
      </svg>,
      title: "Total Complaints",
      count: "1,642",
      percent: "1.15%",
    },
    {
      cardSvg: <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 512 512" }} xmlSpace="preserve"><g><path d="M9.5 15h-6a.5.5 0 0 1 0-1h6a.5.5 0 0 1 0 1zM8.5 18h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1zM8.5 7c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.551 0-1 .449-1 1s.449 1 1 1 1-.449 1-1-.449-1-1-1zM12 12a.5.5 0 0 1-.5-.5v-1c0-.827-.673-1.5-1.5-1.5H7c-.827 0-1.5.673-1.5 1.5v1a.5.5 0 0 1-1 0v-1C4.5 9.122 5.622 8 7 8h3c1.378 0 2.5 1.122 2.5 2.5v1a.5.5 0 0 1-.5.5z" fill="#000000" opacity="1" data-original="#000000"></path><path d="M9.5 21h-7A2.503 2.503 0 0 1 0 18.5v-16C0 1.122 1.122 0 2.5 0h12C15.878 0 17 1.122 17 2.5v6.06a.5.5 0 0 1-1 0V2.5c0-.827-.673-1.5-1.5-1.5h-12C1.673 1 1 1.673 1 2.5v16c0 .827.673 1.5 1.5 1.5h7a.5.5 0 0 1 0 1z" fill="#000000" opacity="1" data-original="#000000"></path><path d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.467-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.467 5.5-5.5-2.467-5.5-5.5-5.5z" fill="#000000" opacity="1" data-original="#000000"></path><path d="M16.5 20a.505.505 0 0 1-.354-.146l-2-2a.5.5 0 0 1 .707-.707l1.622 1.622 3.148-3.598a.5.5 0 1 1 .753.659l-3.5 4a.499.499 0 0 1-.36.17H16.5z" fill="#000000" opacity="1" data-original="#000000"></path></g></svg>,
      title: "Resolved",
      count: "3,642",
      percent: "1.15%",
    },
    {
      cardSvg:
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="18" x="0" y="0" viewBox="0 0 24 24" style={{ enableBackground: "new 0 0 512 512" }} xmlSpace="preserve" class=""><g><path d="M17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.032 0-5.5 2.467-5.5 5.5s2.468 5.5 5.5 5.5 5.5-2.467 5.5-5.5-2.468-5.5-5.5-5.5z" fill="#000000" opacity="1" data-original="#000000" class=""></path><path d="M15.379 20.121a.5.5 0 0 1-.354-.853l4.242-4.242a.5.5 0 0 1 .707.707l-4.242 4.242a.498.498 0 0 1-.353.146z" fill="#000000" opacity="1" data-original="#000000" class=""></path><path d="M19.621 20.121a.502.502 0 0 1-.354-.146l-4.242-4.242a.5.5 0 0 1 .707-.707l4.242 4.242a.5.5 0 0 1-.353.853zM9.5 15h-6a.5.5 0 0 1 0-1h6a.5.5 0 0 1 0 1zM8.5 18h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1zM8.5 7c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3a1.001 1.001 0 0 0 0 2 1.001 1.001 0 0 0 0-2zM12 12a.5.5 0 0 1-.5-.5v-1c0-.827-.673-1.5-1.5-1.5H7c-.827 0-1.5.673-1.5 1.5v1a.5.5 0 0 1-1 0v-1C4.5 9.122 5.621 8 7 8h3c1.379 0 2.5 1.122 2.5 2.5v1a.5.5 0 0 1-.5.5z" fill="#000000" opacity="1" data-original="#000000" class=""></path><path d="M9.5 21h-7A2.503 2.503 0 0 1 0 18.5v-16C0 1.122 1.121 0 2.5 0h12C15.879 0 17 1.122 17 2.5v6.06a.5.5 0 0 1-1 0V2.5c0-.827-.673-1.5-1.5-1.5h-12C1.673 1 1 1.673 1 2.5v16c0 .827.673 1.5 1.5 1.5h7a.5.5 0 0 1 0 1z" fill="#000000" opacity="1" data-original="#000000" class=""></path></g></svg>,
      title: "Pending",
      count: "30,642",
      percent: "1.15%",
    },
  ]

  const TableHeading = [
    {
      label: "#",
      title: "#"
    },
    {
      label: "User Name",
      title: "userName"
    },
    {
      label: "Email",
      title: "email"
    },
    {
      label: "Mobile No.",
      title: "mobileNo"
    },
    {
      label: "Creation Date",
      title: "creationDate"
    },
    {
      label: "Documents Verification",
      title: "documentVer"
    },
    {
      label: "Status",
      title: "status"
    }
  ]

  const ActionData = [
    {
      icon: <i class="fas fa-eye"></i>,
      label: "View",
      action: () => {
        // handle action
      }
    },
    {
      icon: <i class="fas fa-file"></i>,
      label: "Documents",
      action: () => {
        // handle action
      }
    },
    {
      icon: <i class="fas fa-trash-alt"></i>,
      label: "Deactivate",
      action: () => {
        // handle action
      }
    },
  ]

  return (<>
    <MainLayout>
      <MainHeading title={"Complaints"} />
      <div class="row dash-cards">
        {UserCard?.map((item, index) => <div class="col-xl-4 col-sm-6 col-12">
          <Cards {...item} />
        </div>)}
      </div>
      <CommonTable TableHeading={TableHeading} ActionData={ActionData} />
    </MainLayout>
  </>)
}